import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import ReviewSection from "../components/review-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoResultsInYourArea from "../components/seo-results-in-your-area";
import SeoVideoSection from "../components/seo-video-section";
import SeoWhatWeSolve from "../components/seo-what-we-solve";
import SeoWwaSection from "../components/seo-wwa-section";
import blueOne from "../images/1-blue.svg";
import blueTwo from "../images/2-blue.svg";
import blueThree from "../images/3-blue.svg";

const WebDesignSussexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design Sussex" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			ecfImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ecfsmallImg: wpMediaItem(title: { eq: "ecf-two-1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			arrowImg: wpMediaItem(title: { eq: "arrow" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			eccImg: wpMediaItem(title: { eq: "Eastbourne-commerce-white" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			laptopImg: wpMediaItem(title: { eq: "webdesign-laptop" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ecocImg: wpMediaItem(title: { eq: "Eastbourne-commerce-white" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			OASectionImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			foehImg: wpMediaItem(title: { eq: "Wild-Women-Adventures-fullscreen" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Web Design Sussex" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "Web Design Sussex" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	//test
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;
	// var html = `<script>var widget = new SimplybookWidget({"widget_type":"button","url":"https:\/\/connorarchibald.simplybook.it","theme":"skittish","theme_settings":{"timeline_hide_unavailable":"1","hide_past_days":"0","timeline_show_end_time":"0","timeline_modern_display":"as_slots","sb_base_color":"#33bb60","display_item_mode":"block","body_bg_color":"#f7f7f7","sb_review_image":"","dark_font_color":"#494949","light_font_color":"#ffffff","sb_company_label_color":"#333333","hide_img_mode":"0","sb_busy":"#dad2ce","sb_available":"#d3e0f1"},"timeline":"modern","datepicker":"top_calendar","is_rtl":false,"app_config":{"allow_switch_to_ada":0,"predefined":[]},"button_title":"Book now","button_background_color":"#33bb60","button_text_color":"#ffffff","button_position":"right","button_position_offset":"55%"});</script>`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Web Design Sussex",
				item: {
					url: `${siteUrl}/web-design-sussex`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				<script src="//widget.simplybook.it/v2/widget/widget.js"></script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/web-design-sussex`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						page="Web Design Sussex"
						form
						title={`Web Design \n<span class="text-secondary">Sussex</span>`}
						description={
							<span>
								Helping your Sussex business generate more leads and enquiries
								with bespoke web design.
							</span>
						}
					/>
				</section>
				{/* <div>
					<DangerouslySetHtmlContent html={html} />
				</div> */}

				<SeoWwaSection
					spanLg={null}
					heading="Unique web design solutions that help you achieve more"
					text={
						<div>
							<p>
								RJM Digital is a leading <strong>Sussex-based</strong> web design and digital
								marketing agency. Our web designers are dedicated to helping
								ambitious companies in Sussex drive <strong>more traffic</strong> online and
								receive better results from their digital marketing.
							</p>
							<p>
								Finding your website isn't generating you the results you deserve?
								We know how to help you. Not only do our <strong>custom-made</strong> websites look
								great, we build and design responsive websites with the aim to
								specifically drive traffic, generate new leads and increase your
								rankings on search engines results pages <strong>(SERPs)</strong>.
							</p>
							<p>
								Each and every one of our websites are unique to your own <strong>Sussex</strong>{" "}
								business, with customised design and development to represent
								your brand identity in the best light to help you stand out from
								your competitors and gain greater exposure to your ideal customers
								and ultimately help your business grow further and <strong>achieve success</strong> online.
							</p>
						</div>
					}
					buttonText="Book a discovery call"
					buttonUrl="#form"
					imgTop={data.ecfsmallImg.gatsbyImage}
					imgTopAlt={data.ecfsmallImg?.altText}
					imgBot={data.ecfImg.gatsbyImage}
					imgBotAlt={data.ecfImg?.altText}
				/>
				<section className="py-5 py-lg-7">
					<SeoPageLogos
						heading={
							<span>
								We are proud to be trusted by many Sussex
								<br className=" d-none d-lg-block" /> businesses and
								organisations
							</span>
						}
						page="Web Design Sussex"
					/>
				</section>
				<section id="benefits" className="py-5 p-lg-7 bg-dark-grey">
					<Container className="">
						<Row>
							<Col>
								<h2 className="mb-5 text-white display-4 ssp-bold text-uppercase">
									Top benefits of investing in a{" "}
									<span className="text-light-blue">new website</span>
								</h2>
							</Col>
						</Row>
						<Row className=" g-6 g-lg-4 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img
										src={blueOne}
								
										className="w-md-40 w-30"
									/>
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Competitive advantage</h3>
									<p className="text-white">
										A new website that is professionally designed and developed
										will set your business apart from your competition both in
										Sussex and beyond. Your new website can showcase your unique
										selling points and highlight your strengths to ultimately
										differentiate yourself in your industry.
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img
										src={blueTwo}
								
										className="w-md-40 w-30"
									/>
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">
										Better visibility in search engines
									</h3>
									<p className="text-white">
										Implementing effective search engine optimisation (SEO)
										strategies in your new website will help to improve your
										website's visibility in the search engine results pages,
										therefore helping to drive more organic traffic to your site.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img
										src={blueThree}
								
										className="w-md-40 w-30"
									/>
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Mobile-Friendly design</h3>
									<p className="text-white">
										With more and more people choosing to access the internet via
										their phones, having a website that is optimised for different
										screen sizes is crucial. A new website will be designed to be
										responsive and mobile-friendly, providing a seamless user
										experience across different devices.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.2622758441216!2d0.27970641574303434!3d50.77071147952087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df735183be68e9%3A0x51b877a5d82a3df!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1663160300414!5m2!1sen!2suk" />
				<div className="bg-light-grey">
					<SeoResultsInYourArea
						heading="Reach the top of Google"
						text={
							<div className="text-white">
								<p>
									Don't you just hate it when you have a website that you're
									incredibly proud of, but you aren't receiving nearly enough
									enquiries, leads or customers as you'd wished for? This
									shows how crucial <strong>SEO</strong> is to not only your website but to
									your business too.
								</p>
								<p>
									<strong>Search engine optimisation</strong> (SEO) is when you optimise your website
									in order to appear higher in the search rankings on the search engine
									results pages. RJM Digital work hard to help you to optimise your site
									by conducting keyword research, link building and content optimisation.
									When done correctly you can achieve <strong>greater visibility</strong> to potential
									customers searching for your keywords and ultimately increase your
									organic traffic.
								</p>
								<p>
									Every bespoke website we design, develop and build is made to
									be found on all major search engines, so your new website will
									never get lost in the rankings. We understand that SEO is a
									{" "}<strong>continually evolving</strong> process that requires constant monitoring
									and adaptation in order to be truly successful. We are dedicated
									to assisting Sussex businesses by offering you the expertise
									needed to <strong>surpass competitors</strong> and generate leads through
									effective SEO strategies.
								</p>
							</div>
						}
						buttonText="Find out more about SEO in Eastbourne"
						buttonUrl="/contact-us"
						imgTop={data.laptopImg.gatsbyImage}
						imgTopAlt={data.laptopImg?.altText}
						imgBot={data.eccImg.gatsbyImage}
						imgBotAlt={data.eccImg?.altText}
						imgBot2={data.arrowImg.gatsbyImage}
						imgBot2Alt={data.arrowImg?.altText}
						bottomContent={null}
					/>
					<section className="py-5 bg-primary py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="mb-3 text-white display-4 ssp-bold text-uppercase">
										Our achievements
									</h2>
									<p className="text-white">
										Over the years working with businesses and organisations in and around Sussex, we know
										what works well for them. We understand the unique needs and requirements that your
										{" "}<strong>Sussex-based</strong> business has and will build and design you a website that exceeds your
										expectations. No web project is too large for RJM Digital to take on, call us today
										to discuss your <strong>web design</strong> ideas.
									</p>
									<p className="pb-4 text-white">
										We have proven results, the figures speak for themselves…
									</p>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col>
									<Row
										style={{ borderRadius: "15px" }}
										className="p-3 pt-4 bg-white "
									>
										<Col>
											<Row className="g-5 gy-lg-0 px-xl-7 x-5 ">
												<Col className="text-center" lg={4}>
													<h3 className="display-4 ssp-bold text-primary">
														50+
													</h3>
													<p className="ssp-semibold">Sussex based customers</p>
												</Col>
												<Col className="text-center" lg={4}>
													<h3 className="display-4 ssp-bold text-primary">6</h3>
													<p className="ssp-semibold">
														Average months for
														<br /> Return on Investment
													</p>
												</Col>
												<Col className="text-center" lg={4}>
													<h3 className="display-4 ssp-bold text-primary">
														5+
													</h3>
													<p className="ssp-semibold">
														Years working with Sussex
														<br /> businesses
													</p>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-white py-lg-7">
						<Container>
							<Row className="align-items-center">
								<Col lg={4}>
									<GatsbyImage
										className="w-100"
										image={data.ecocImg.gatsbyImage}
										alt={data.ecocImg?.altText}
									/>
								</Col>
								<Col lg={8}>
									<p>
										We are honoured to be trusted by a <strong>local Chamber of Commerce</strong> in Sussex providing SEO
										support to local businesses in Sussex, helping them recover their businesses with the
										assistance of a new website following the COVID-19 pandemic back in 2020. Our team of
										software developers and web designers are committed to delivering exceptional results
										and driving business growth for Sussex businesses through our tailored SEO strategies
										and bespoke web design and development plans.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<SeoWhatWeSolve
						heading="How we build our websites"
						image={data.foehImg.gatsbyImage}
						imageAlt={data.foehImg?.altText}
						imgHeight="35rem"
						text={
							<div>
								<h3 className="text-primary fs-2 ">
									Wordpress and GatsbyJS{" "}
									<span className="text-secondary">website</span>
								</h3>
								<p>
									Each of our websites are built using a popular open-source <strong>content
									management system</strong> such as Wordpress. This allows you to add content,
									media files and data to your website efficiently. In order to improve
									the security of your website, we add an additional layer to the content
									management system called GatsbyJS. GatsbyJS has many benefits including
									providing your new website with ultra-fast loading speeds, resulting in
									a greater user experience for your customers, <strong>responsive</strong>{" "}
									design and overall a higher website <strong>conversion</strong> rate.
								</p>
								<h3 className="mt-5 text-primary fs-2">
									Bespoke website design{" "}
									<span className="text-secondary">Sussex</span>
								</h3>
								<p>
									At RJM Digital we specialise in building your Sussex business a
									website tailored to your unique needs and requirements. Our user
									experience and <strong>graphic design</strong> team will design your bespoke website
									using our advanced website mock-up tools which allow you unlimited
									revisions and changes to ensure your new website is something that
									you’re proud of. All our designs are unique to each individual
									business, meaning no templated websites are used, allowing your
									Sussex business to stand out from the crowd with a bespoke website
									that captures the attention of your ideal customers and target audience.
								</p>
							</div>
						}
					/>
				</div>
				{/* <div id="pricing">
					<PricingSection />
				</div> */}

				<div id="approach">
					<OASection
						buttonUrl="#map-section"
						image={data.OASectionImg.gatsbyImage}
						imageAlt={data.OASectionImg?.altText}
						imgHeight="50rem"
						heading="Our 4 Step Approach"
						brief="With our experienced team by your side, building or redesigning a website will become effortless. We'll provide you with support and guidance throughout the entire process, in order to ensure that your new website not only reflects your brand but will also work well as a marketing tool for your Sussex business."
						design="Once we have fully understood the requirements and needs of your Sussex business, we can begin generating ideas for the design of your new website. We will keep you up-to-date with regular check-in sessions, where you are able to make any necessary adjustments to the design of your website."
						build="In order to ensure that your website is user-friendly and accessible on any device as well as having super fast loading speeds, we use the most advanced technology. We will then test your site to make sure that everything is running as it should and that it is prepped for SEO."
						launch="As soon as our final checks are complete, we will launch your new website. Our friendly and reliable support team will always be available to you whenever you need to make any necessary updates or changes to your website."
					/>
				</div>

				<SeoVideoSection videos={data.videos} />

				<section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="Web Design Sussex Reviews"
						page="Our Web Services"
					/>
				</section>
				<section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-primary fs-1">
									Recent Eastbourne Web Design blog articles
								</h2>
							</Col>
						</Row>
						<Row className="g-5 gx-lg-7">
							{data.allWpPost.nodes.map((blog, index) => (
								<Col lg={6}>
									<div
										style={{
											overflow: "hidden",
											borderRadius: "20px",
											boxShadow: "0px 3px 60px #00000029",
										}}
									>
										<GatsbyImage
											image={blog.blogFields.featuredImage.gatsbyImage}
											alt={blog.blogFields.featuredImage.altText}
											className="w-100 seo-blog-image"
										/>
										<div className="p-4 bg-white">
											<h2 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
												{blog.title}
											</h2>

											<Link
												to={`/blog/${convertToSlug(blog.blogFields.category)}/${
													blog.slug
												}`}
												className="med-grey-link ssp-bold"
											>
												READ BLOG
											</Link>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default WebDesignSussexPage;
